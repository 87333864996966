

















import {Component,Watch} from 'vue-property-decorator';
import Vue from 'vue';
import BannerTitled from '@/components/general/BannerTitled.vue';
import GalleryCard from '@/components/cards/GalleryCard.vue';
import {GalleryFilters} from "@/collections/filters";
import firebase from 'firebase';

@Component({
    components:{
        BannerTitled,
        GalleryCard
    }
})
export default class Gallery extends Vue { 

    filter : string =''
    storage = firebase.storage();
   

    created() { 
        this.filterImages()
         
    }

    @Watch('$route')
    watchRoute(){
       this.filterImages();
    }

    get getTitleByFilterName() : string {
        for (var i=0;i<Object.values(GalleryFilters).length;i++){
            if (Object.values(GalleryFilters)[i] === this.filter){
                return 'filteredPhotos.'+ this.filter
            }
        }
        return ''
    }

    filterImages(){
            this.filter = this.$route.params.filter;          
    }

    get getImages(){    
      return  this.$t(`galleryImages[${Number(this.filter)-1}].photos`)
    }
}

