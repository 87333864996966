

























import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';
import firebase from 'firebase'
import SvgIcon from '@/components/general/SvgIcon.vue';
import Video from '@/components/general/Video.vue';

@Component({
   components:{
       SvgIcon,
       Video
   }
})
export default class BookCard extends Vue {
    @Prop() category! : string;
    @Prop() item! : any;
    image : string = '';
    storage = firebase.storage();


     get getImage(){  
        this.storage.ref(`galeria/${this.category}/${this.item.id}.png`).getDownloadURL().then((url)=>{
           this.image =  url
        })
      return this.image;
    }
}

